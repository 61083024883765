import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../imagenes/Logo.png";

const Navbar = () => {
    const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsInfoMenuOpen(true);
    };

    const handleMouseLeave = () => {
        setIsInfoMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const [activeScreen, setActiveScreen] = useState('main'); // 'main' para opciones, 'suboptionsX' para subopciones

    const handleBack = () => {
        setActiveScreen('main'); // Volver a la pantalla principal
    };

    const handleBackOpt = () => {
        setActiveScreen('options');
    };

    return (
        <nav className="bg-white p-4 shadow-lg">
            <div className="flex justify-between max-w-screen-xl mx-auto">
                {/* Contenedor del logo y del menú */}
                <div className="flex items-center gap-8">
                    {/* Logo */}
                    <div className="flex items-center">
                        <img src={Logo} alt="logo" className="md:cursor-pointer w-28" />
                    </div>

                    {/* Menú en pantallas grandes */}
                    <div className="pt-3">
                        <ul className={`hidden md:flex gap-8 text-xl text-blue-900`}>
                            <li className="relative group">
                                <Link to="/" className="py-2 inline-block relative group">
                                    <span className="block px-4 md:group-hover:font-semibold">
                                        Vuelos
                                    </span>
                                    <span className="absolute left-1/2 bottom-0 h-[2px] w-[30%] bg-blue-900 transform -translate-x-1/2 translate-y-[2px] scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                                </Link>
                            </li>
                            <li className="relative group">
                                <Link to="https://tamep.transclicksolutions.com/check-in/search-booking" className="py-2 px-3 inline-block relative group">
                                    <span className="block px-2 md:group-hover:font-semibold">
                                        Web Check In
                                    </span>
                                    <span className="absolute left-1/2 bottom-0 h-[2px] w-[30%] bg-blue-900 transform -translate-x-1/2 translate-y-[1px] scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                                </Link>
                            </li>
                            <li className="relative group" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <button>
                                    <Link to="/centro-de-informacion" className="py-2 px- inline-block relative group">
                                        <span className="block px-2 md:group-hover:font-semibold">
                                            Centro de información
                                        </span>
                                        <span className="absolute left-1/2 bottom-0 h-[2px] w-[30%] bg-blue-900 transform -translate-x-1/2 translate-y-[4px] scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                                    </Link>
                                </button>

                                {/* Menú desplegable con columnas */}
                                {isInfoMenuOpen && (
                                    <div className="relative">
                                        <div className="absolute left-[-50px] top-2 bg-white py-4 shadow-lg grid grid-cols-2 w-80 text-base transform transition-all duration-400 hover:scale-105 hover:shadow-2xl">
                                            {/* "barra" en la parte superior azul para iniciar el contenido de las opciones y el submenú*/}
                                            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full">
                                                <div className="w-60 h-5 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-blue-900"></div>
                                            </div>

                                            <div>
                                                <ul className="space-y-2">
                                                    <li>
                                                        <Link
                                                            to="/opcion1/content11"
                                                            className="block text-blue-900 hover:border-left hover:border-l-blue-900 hover:border-l-4 hover:bg-white hover:text-blue-900 hover:bg-gray-100 p-1 px-6 font-semibold transition duration-300 ease-in-out transform hover:translate-x-1">
                                                            Opción 1
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content32"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4  hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 1
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content33"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4  hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 2
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content34"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 3
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="/opcion2/content21"
                                                            className="block text-blue-900 p-1 px-6 font-semibold transition hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 duration-300 ease-in-out transform hover:translate-x-1">
                                                            Opción 2
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content32"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 1
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content33"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 2
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content34"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 3
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <ul className="space-y-2">
                                                    <li>
                                                        <Link
                                                            to="/opcion3/content31"
                                                            className="block text-blue-900 p-1 px-6 font-semibold transition hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 duration-300 ease-in-out transform hover:translate-x-1">
                                                            Opción 3
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content32"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 1
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content33"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 2
                                                        </Link>
                                                        <Link
                                                            to="/opcion3/content34"
                                                            className="block text-gray-400 p-1 px-8 transition duration-300 hover:border-l-blue-900 hover:border-l-4 hover:bg-gray-100 ease-in-out transform hover:translate-x-1">
                                                            Subopción 3
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </li>
                            <li className="relative group">
                                <Link
                                    to="/formulario/formulario"
                                    className="py-2 inline-block relative group"
                                >
                                    <span className="block px-4 md:group-hover:font-semibold">
                                        Inicio de sesion
                                    </span>
                                    <span className="absolute left-1/2 bottom-0 h-[2px] w-[30%] bg-blue-900 transform -translate-x-1/2 translate-y-[2px] scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Hamburguesa */}
                <button
                    className="text-blue-900 md:hidden text-3xl w-12 h-12 pt-6"
                    onClick={toggleMobileMenu}
                >
                    ☰
                </button>
                {/* Menú en pantallas pequeñas */}
                {isMobileMenuOpen && (
                    <div className="md:hidden absolute top-24 left-0 right-0 bg-white shadow-lg flex flex-col p-4 my-2">
                        {activeScreen === 'main' && (
                            <>
                                <Link
                                    to="/"
                                    className="font-semibold block py-2 px-4 text-lg text-blue-900 hover:bg-blue-900 hover:text-white w-full text-center relative group"
                                >
                                    Vuelos
                                </Link>
                                <Link
                                    to="/"
                                    className="font-semibold block py-2 px-4 text-lg text-blue-900 hover:bg-blue-900 hover:text-white w-full text-center relative group"
                                >
                                    Web Check In
                                </Link>
                                <button onClick={() => setActiveScreen('options')} className="font-semibold block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center">
                                    Centro de Información
                                </button>
                            </>
                        )}
                        {activeScreen === 'options' && (
                            <div className="w-full">
                                <button
                                    onClick={handleBack}
                                    className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                >
                                    Volver
                                </button>
                                <ul className="space-y-1 w-full">
                                    <li className="w-full">
                                        <button
                                            onClick={() => setActiveScreen('suboptions1')}
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Opción 1
                                        </button>
                                    </li>
                                    <li className="w-full">
                                        <button
                                            onClick={() => setActiveScreen('suboptions2')}
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Opción 2
                                        </button>
                                    </li>
                                    <li className="w-full">
                                        <button
                                            onClick={() => setActiveScreen('suboptions3')}
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Opción 3
                                        </button>
                                    </li>
                                    <li className="w-full">
                                        <button
                                            onClick={() => setActiveScreen('suboptions4')}
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Opción 4
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {activeScreen === 'suboptions1' && (
                            <div className="w-full">
                                <button
                                    onClick={handleBackOpt}
                                    className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                >
                                    Volver
                                </button>
                                <ul className="space-y-1 w-full">
                                    <li className="w-full">
                                        <Link
                                            to="/opcion1/content11"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 1
                                        </Link>
                                        <Link
                                            to="/opcion1/content12"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 2
                                        </Link>
                                        <Link
                                            to="/opcion1/content13"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {activeScreen === 'suboptions2' && (
                            <div className="w-full">
                                <button
                                    onClick={handleBackOpt}
                                    className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                >
                                    Volver
                                </button>
                                <ul className="space-y-1 w-full">
                                    <li className="w-full">
                                        <Link
                                            to="/opcion2/content21"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 1
                                        </Link>
                                        <Link
                                            to="/opcion2/content22"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 2
                                        </Link>
                                        <Link
                                            to="/opcion2/content23"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {activeScreen === 'suboptions3' && (
                            <div className="w-full">
                                <button
                                    onClick={handleBackOpt}
                                    className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                >
                                    Volver
                                </button>
                                <ul className="space-y-1 w-full">
                                    <li className="w-full">
                                        <Link
                                            to="/opcion3/content31"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 1
                                        </Link>
                                        <Link
                                            to="/opcion3/content32"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 2
                                        </Link>
                                        <Link
                                            to="/opcion3/content33"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                        {activeScreen === 'suboptions4' && (
                            <div className="w-full">
                                <button
                                    onClick={handleBackOpt}
                                    className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                >
                                    Volver
                                </button>
                                <ul className="space-y-1 w-full">
                                    <li className="w-full">
                                        <Link
                                            to="/opcion4/content41"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 1
                                        </Link>
                                        <Link
                                            to="/opcion4/content42"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 2
                                        </Link>
                                        <Link
                                            to="/opcion4/content43"
                                            className="block text-blue-800 hover:bg-blue-900 hover:text-white px-4 py-2 w-full text-center"
                                        >
                                            Subopción 3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                )}
                
            </div>
        </nav>
    );
};

export default Navbar;
