import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './componente/navbar';
import Content11 from './componente/opcion1/content11';
import Content12 from './componente/opcion1/content12';
import Content13 from './componente/opcion1/content13';
import Content14 from './componente/opcion1/content14';
import Content21 from './componente/opcion2/content21';
import Content22 from './componente/opcion2/content22';
import Content23 from './componente/opcion2/content23';
import Content24 from './componente/opcion2/content24';
import Content31 from './componente/opcion3/content31';
import Content32 from './componente/opcion3/content32';
import Content33 from './componente/opcion3/content33';
import Content34 from './componente/opcion3/content34';
import Content41 from './componente/opcion4/content41';
import Content42 from './componente/opcion4/content42';
import Content43 from './componente/opcion4/content43';
import Content44 from './componente/opcion4/content44';
import CentroDeInformacion from './componente/centro-de-informacion';
import Formulario from './componente/formulario/formulario';
import Pagina from './componente/pagina/pagina';

function App() {
  return (
    <Router>
      <Navbar />

      <Routes>

        <Route path="/pagina/pagina" element={<Pagina />} />
        <Route path="/formulario/formulario" element={<Formulario />} />
        <Route path="/centro-de-informacion" element={<CentroDeInformacion />} />
        <Route path="/opcion1/content11" element={<Content11 />} />
        <Route path="/opcion1/content12" element={<Content12 />} />
        <Route path="/opcion1/content13" element={<Content13 />} />
        <Route path="/opcion1/content14" element={<Content14 />} />

        <Route path="/opcion2/content21" element={<Content21 />} />
        <Route path="/opcion2/content22" element={<Content22 />} />
        <Route path="/opcion2/content23" element={<Content23 />} />
        <Route path="/opcion2/content24" element={<Content24 />} />

        <Route path="/opcion3/content31" element={<Content31 />} />
        <Route path="/opcion3/content32" element={<Content32 />} />
        <Route path="/opcion3/content33" element={<Content33 />} />
        <Route path="/opcion3/content34" element={<Content34 />} />

        <Route path="/opcion4/content41" element={<Content41 />} />
        <Route path="/opcion4/content42" element={<Content42 />} />
        <Route path="/opcion4/content43" element={<Content43 />} />
        <Route path="/opcion4/content44" element={<Content44 />} />
      </Routes>
    </Router>
  );
}

export default App;