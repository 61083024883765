import React from "react";

// Componente funcional 'Content'
const Pagina = () => {
    return ( 
        // Sección principal con flexbox para alinear los elementos en una columna y fondo de imagen
        <section className="flex flex-col min-h-screen bg-slate-800 text-white bg-cover bg-center" 
                 style={{ backgroundImage: "url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOJL9NvsEhorXFaRJACUAAQnvxuozF0sRWIg&s')" }}>
            
            {/* Contenedor flex para organizar el menú lateral y el contenido principal */}
            <div className="flex flex-col md:flex-row md:flex-wrap items-center justify-center h-full space-y-6 md:space-y-0">
                
                {/* Sección del menú lateral */}
                <section className="w-full md:w-1/4 p-4">
                    {/* Contenedor para el título del menú lateral */}
                    <div className="text-center m-8 mx-auto bg-black bg-opacity-50 p-8 rounded-lg shadow-lg">
                        <p className="font-light text-3xl mt-5">Pagina</p>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default Pagina;