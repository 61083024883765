import React from "react";
import "../estilos/centro-de-informacion.css";

// Imágenes para las cajas de contenido
import imagen1 from '../imagenes/img-content11/discapacitado.png';
import imagen2 from '../imagenes/img-content11/discapacitado.png';
import imagen3 from '../imagenes/img-content11/discapacitado.png';
import imagen4 from '../imagenes/img-content11/discapacitado.png';

const CentroDeInformacion = () => {
    return (
        // Contenedor principal de la sección
        <section className="container">

            {/* Contenedor de la barra de búsqueda */}
            <div className="search-section">
                <div className="search-content">
                    {/* Texto de bienvenida y descripción */}
                    <div className="search-text">
                        <h1>Centro de ayuda</h1>
                        <p>Podrás navegar por nuestras categorías y buscar información para resolver tus consultas.</p>
                    </div>

                    {/* Barra de búsqueda */}
                    <div className="search-bar">
                        {/* Input para buscar palabras clave o preguntas */}
                        <input type="text" placeholder="Ingresa una palabra clave o una pregunta" />
                    </div>
                </div>
            </div>

            {/* Contenedor principal de las cajas de contenido */}
            <div className="content-container">
                {/* Contenedor para organizar las cajas en una cuadrícula */}
                <div className="box-grid">
                    
                    {/* Primera caja de contenido */}
                    <div className="box">
                        <img src={imagen1} alt="Descripción 1" className="box-image" />
                        <p className="box-text">Descripción para la primera caja de contenido.</p>
                    </div>

                    {/* Segunda caja de contenido */}
                    <div className="box">
                        <img src={imagen2} alt="Descripción 2" className="box-image" />
                        <p className="box-text">Descripción para la segunda caja de contenido.</p>
                    </div>

                    {/* Tercera caja de contenido */}
                    <div className="box">
                        <img src={imagen3} alt="Descripción 3" className="box-image" />
                        <p className="box-text">Descripción para la tercera caja de contenido.</p>
                    </div>

                    {/* Cuarta caja de contenido */}
                    <div className="box">
                        <img src={imagen4} alt="Descripción 4" className="box-image" />
                        <p className="box-text">Descripción para la cuarta caja de contenido.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CentroDeInformacion;
