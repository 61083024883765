import React from "react";
/* Imágenes de los botones */
// Importación de las imágenes que se utilizarán en los botones del menú lateral
import boton1Img from '../../imagenes/img-content11/equipaje.png';
import boton2Img from '../../imagenes/img-content11/discapacitado.png';
import boton3Img from '../../imagenes/img-content11/formulario.png';
import boton4Img from '../../imagenes/img-content11/veterinario.png';
import boton5Img from '../../imagenes/img-content11/pregunta.png';

// Componente funcional 'Content14' que estructura la pantalla
const Content14 = () => {
    return (
        // Sección principal con flexbox para alinear el menú lateral y el contenido principal en una fila
        <section
            className="flex flex-col min-h-screen bg-slate-800 text-white bg-cover bg-center"
            // Aplicamos una imagen de fondo desde una URL externa
            style={{
                backgroundImage:
                    "url('https://carlaconwifi.com/wp-content/uploads/2022/07/vuelosbaratos.jpg')",
            }}
        >
            {/* Contenedor padre centralizado */}
            <div className="container-center">

                {/* Menú lateral */}
                <aside className="sidebar-container hidden md:flex">
                    <nav className="sidebar-content">
                        {/* Opción 1 del menú lateral */}
                        <div className="nav-option">
                            <img src={boton1Img} alt="Equipaje" className="icon" />
                            <h3>Equipaje</h3>
                        </div>
                        {/* Opción 2 del menú lateral */}
                        <div className="nav-option">
                            <img src={boton2Img} alt="Necesidades Especiales" className="icon" />
                            <h3>Necesidades <br /> Especiales</h3>
                        </div>
                        {/* Opción 3 del menú lateral */}
                        <div className="nav-option">
                            <img src={boton3Img} alt="Formularios" className="icon" />
                            <h3>Formularios</h3>
                        </div>
                        {/* Opción 4 del menú lateral */}
                        <div className="nav-option">
                            <img src={boton4Img} alt="Mascotas" className="icon" />
                            <h3>Mascotas</h3>
                        </div>
                        {/* Opción 5 del menú lateral */}
                        <div className="nav-option">
                            <img src={boton5Img} alt="Preguntas Frecuentes" className="icon" />
                            <h3>Preguntas <br /> Frecuentes</h3>
                        </div>
                    </nav>
                </aside>

                {/* Contenido principal */}
                <main className="content-section w-full md:w-auto">
                    {/* Contenedor para el contenido principal */}
                    <div className="p-8 bg-white rounded-lg flex flex-col items-center max-w-full max-h-[80vh]">
                        {/* Título principal del contenido */}
                        <h1 className="text-4xl font-bold text-center text-yellow-400 underline mb-4">
                            PREGUNTAS FRECUENTES
                        </h1>

                        {/* Contenedor para las filas de contenido */}
                        <div className="space-y-8 overflow-y-auto">
                            {/* Primera fila de contenido */}
                            <div className="p-2 flex flex-col md:flex-row-reverse items-center justify-center">
                                {/* Contenedor de texto */}
                                <div className="p-6 relative text-gray-950 text-left md:w-5/6 pb-0">
                                    {/* Subtítulo */}
                                    <h2 className="font-bold text-xl mb-2">Subtítulo 1</h2>
                                    {/* Descripción */}
                                    <p>
                                        Sed ut perspiciatis unde omnis iste natus error sit
                                        voluptatem accusantium doloremque laudantium, totam rem
                                        aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim.
                                    </p>
                                    {/* Línea vertical al lado derecho */}
                                    <div className="absolute top-0 right-0 h-full border-r-8 border-blue-900"></div>
                                </div>
                                {/* Imagen asociada */}
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_QIJH0Mt-dgJ3rS5Igud_VCdEuKJ8_ZGuLA&s"
                                    alt="Descripción de la imagen"
                                    className="md:w-1/4 p-2 max-w-xs mx-auto"
                                />
                            </div>

                            {/* Segunda fila de contenido */}
                            <div className="p-2 flex flex-col md:flex-row items-center justify-center">
                                {/* Contenedor de texto */}
                                <div className="p-6 relative text-gray-950 text-left md:w-5/6 pb-0">
                                    {/* Línea vertical al lado izquierdo */}
                                    <div className="absolute top-0 left-0 h-full border-r-8 border-yellow-400"></div>
                                    {/* Subtítulo */}
                                    <h2 className="font-bold text-xl mb-2">Subtítulo 2</h2>
                                    {/* Descripción */}
                                    <p>
                                        Sed ut perspiciatis unde omnis iste natus error sit
                                        voluptatem accusantium doloremque laudantium, totam rem
                                        aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim.
                                    </p>
                                </div>
                                {/* Imagen asociada */}
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_QIJH0Mt-dgJ3rS5Igud_VCdEuKJ8_ZGuLA&s"
                                    alt="Descripción de la imagen"
                                    className="md:w-1/4 p-2 max-w-xs mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </section>
    );
};

export default Content14;
