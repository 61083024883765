import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Aquí puedes realizar la verificación del correo y contraseña
    if (usuario === 'admin' && password === 'smstamep2023') {
      // Redirigir a la pestaña deseada si la autenticación es exitosa
      navigate('/pagina/pagina');
    } else {
      alert('Correo o contraseña incorrectos');
    }
  };

  return (
    <div className="m-14 p-6 max-w-md mx-auto bg-white rounded-lg shadow-md border border-gray-400">
      <h2 className="text-2xl font-bold mb-4 ml-28 text-blue-900">INICIO SESIÓN</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        
        {/* Campo para el correo */}
        <div>
          <label htmlFor="text" className="block text-sm font-medium text-gray-700">Usuario:</label>
          <input
            type="text"
            id="usuario"
            name="usuario"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            placeholder="Ingrese su correo"
            required
          />
        </div>
        
        {/* Campo para la contraseña */}
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Contraseña:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            placeholder="Ingrese su contraseña"
            required
          />
        </div>
        
        {/* Botón para enviar el formulario */}
        <div>
          <button type="submit" className="w-full bg-blue-900 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700">
            Iniciar Sesión
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;