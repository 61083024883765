import React from "react";
import "../../estilos/menu-central.css";
/* Imágenes de los botones */
// Importación de las imágenes que se usarán como íconos en los botones del menú
import boton1Img from '../../imagenes/img-content11/equipaje.png';
import boton2Img from '../../imagenes/img-content11/discapacitado.png';
import boton3Img from '../../imagenes/img-content11/formulario.png';
import boton4Img from '../../imagenes/img-content11/veterinario.png';
import boton5Img from '../../imagenes/img-content11/pregunta.png';

// Componente funcional 'Content11' que estructura la pantalla
const Content11 = () => {
    return (
        <section 
            // Establece la sección principal con un fondo de pantalla completo y propiedades flexibles para asegurar el diseño responsivo
            className="flex flex-col min-h-screen bg-slate-800 text-white bg-cover bg-center"
            // Aplica una imagen de fondo específica para esta sección
            style={{ backgroundImage: "url('https://carlaconwifi.com/wp-content/uploads/2022/07/vuelosbaratos.jpg')" }}>
            
            {/* Contenedor principal que centraliza el menú lateral y el contenido */}
            <div className="container-center">
                
                {/* Menú lateral (visible solo en pantallas medianas y grandes) */}
                <aside className="sidebar-container hidden md:flex">
                    {/* Navegación con botones que contienen un ícono y un título */}
                    <nav className="sidebar-content">
                        {/* Opción 1: Equipaje */}
                        <div className="nav-option">
                            <img src={boton1Img} alt="Equipaje" className="icon" />
                            <h3>Equipaje</h3>
                        </div>
                        {/* Opción 2: Necesidades Especiales */}
                        <div className="nav-option">
                            <img src={boton2Img} alt="Necesidades Especiales" className="icon" />
                            <h3>Necesidades <br /> Especiales</h3>
                        </div>
                        {/* Opción 3: Formularios */}
                        <div className="nav-option">
                            <img src={boton3Img} alt="Formularios" className="icon" />
                            <h3>Formularios</h3>
                        </div>
                        {/* Opción 4: Mascotas */}
                        <div className="nav-option">
                            <img src={boton4Img} alt="Mascotas" className="icon" />
                            <h3>Mascotas</h3>
                        </div>
                        {/* Opción 5: Preguntas Frecuentes */}
                        <div className="nav-option">
                            <img src={boton5Img} alt="Preguntas Frecuentes" className="icon" />
                            <h3>Preguntas <br /> Frecuentes</h3>
                        </div>
                    </nav>
                </aside>
                
                {/* Contenido principal (visible a la derecha del menú lateral en pantallas medianas y grandes) */}
                <main className="content-section w-full md:w-auto">
                    {/* Contenedor para el contenido textual y la imagen */}
                    <div className="p-10 bg-white rounded-lg flex flex-col md:flex-row items-center gap-8">
                        {/* Sección de texto a la izquierda */}
                        <div className="w-full md:w-1/2">
                            {/* Título del contenido */}
                            <h1 className="text-4xl font-bold text-center text-yellow-400 underline mb-4">EQUIPAJE</h1>
                            {/* Contenedor de texto con desplazamiento para contenido extenso */}
                            <div className="text-gray-950 text-justify h-80 overflow-y-scroll p-2">
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim.
                                </p>
                            </div>
                        </div>

                        {/* Sección de la imagen a la derecha */}
                        <div className="w-full md:w-1/2 flex justify-center items-center">
                            {/* Imagen representativa del contenido actual */}
                            <img 
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_QIJH0Mt-dgJ3rS5Igud_VCdEuKJ8_ZGuLA&s" 
                                alt="Equipaje"
                                className="rounded-lg shadow-md h-96 object-cover" />
                        </div>
                    </div>
                </main>
            </div>
        </section>
    );
};

export default Content11;
