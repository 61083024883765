import React from "react";
/* Imágenes de los botones */
// Importación de las imágenes que se utilizarán en los botones del menú lateral
import boton1Img from '../../imagenes/img-content11/equipaje.png';
import boton2Img from '../../imagenes/img-content11/discapacitado.png';
import boton3Img from '../../imagenes/img-content11/formulario.png';
import boton4Img from '../../imagenes/img-content11/veterinario.png';
import boton5Img from '../../imagenes/img-content11/pregunta.png';

// Componente funcional 'Content12' que estructura la pantalla
const Content12 = () => {
    return (
        // Sección principal que ocupa toda la pantalla con una disposición en columna y fondo de imagen
        <section className="flex flex-col min-h-[85vh] bg-slate-800 text-white bg-cover bg-center"
            // Aplica una imagen de fondo desde una URL externa
            style={{ backgroundImage: "url('https://carlaconwifi.com/wp-content/uploads/2022/07/vuelosbaratos.jpg')" }}>

            {/* Contenedor principal centrado que agrupa el menú lateral y el contenido */}
            <div className="container-center">

                {/* Menú lateral que solo se muestra en pantallas medianas y grandes */}
                <aside className="sidebar-container hidden md:flex">
                    {/* Navegación del menú con los botones de opciones */}
                    <nav className="sidebar-content">
                        {/* Opción del menú: Equipaje */}
                        <div className="nav-option">
                            <img src={boton1Img} alt="Equipaje" className="icon" />
                            <h3>Equipaje</h3>
                        </div>
                        {/* Opción del menú: Necesidades Especiales */}
                        <div className="nav-option">
                            <img src={boton2Img} alt="Necesidades Especiales" className="icon" />
                            <h3>Necesidades <br /> Especiales</h3>
                        </div>
                        {/* Opción del menú: Formularios */}
                        <div className="nav-option">
                            <img src={boton3Img} alt="Formularios" className="icon" />
                            <h3>Formularios</h3>
                        </div>
                        {/* Opción del menú: Mascotas */}
                        <div className="nav-option">
                            <img src={boton4Img} alt="Mascotas" className="icon" />
                            <h3>Mascotas</h3>
                        </div>
                        {/* Opción del menú: Preguntas Frecuentes */}
                        <div className="nav-option">
                            <img src={boton5Img} alt="Preguntas Frecuentes" className="icon" />
                            <h3>Preguntas <br /> Frecuentes</h3>
                        </div>
                    </nav>
                </aside>

                {/* Contenido principal que se muestra al lado derecho del menú lateral */}
                <main className="content-section w-full md:w-auto">
                    {/* Contenedor principal con un fondo blanco, bordes redondeados y disposición en columna */}
                    <div className="p-10 bg-white rounded-lg flex flex-col items-center gap-5">

                        {/* Título del contenido */}
                        <h1 className="text-4xl font-bold text-center text-yellow-400 underline">NECESIDADES ESPECIALES</h1>

                        {/* Contenedor para el texto y su disposición */}
                        <div className="bg-white rounded-lg flex flex-col md:flex-row overflow-y-scroll items-center">
                            
                            {/* Primera sección de texto, con scroll vertical para manejar el contenido extenso */}
                            <div className="text-gray-950 text-justify md:h-80 p-2 md:flex-row items-center">
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim.
                                </p>
                            </div>

                            {/* Segunda sección de texto, similar a la primera para dar continuidad al contenido */}
                            <div className="text-gray-950 text-justify md:h-80 p-2 md:flex-row items-center">
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim. <br />
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim.
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </section>
    );
};

export default Content12;
