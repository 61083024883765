import React, { useState } from "react";
/* Imágenes de las flechas de siguiente y atras para intercambiar los formularios */
import flechaIzquierda from "../../imagenes/flecha-izquierda.png";
import flechaDerecha from "../../imagenes/flecha-correcta.png";
/* Imágenes de los botones */
// Importación de las imágenes que se utilizarán en los botones del menú lateral
import boton1Img from '../../imagenes/img-content11/equipaje.png';
import boton2Img from '../../imagenes/img-content11/discapacitado.png';
import boton3Img from '../../imagenes/img-content11/formulario.png';
import boton4Img from '../../imagenes/img-content11/veterinario.png';
import boton5Img from '../../imagenes/img-content11/pregunta.png';

// Componente funcional 'Content13' que estructura la pantalla
const Content13 = () => {
    // Estado para manejar el índice de la diapositiva actual
    const [currentSlide, setCurrentSlide] = useState(0);

    // Datos de las diapositivas: cada diapositiva tiene un título y una imagen
    const slides = [
        {
            title: "Formulario Pasajero en Gestión",
            image: "https://tamep.reflex.run/fr2.jpeg"
        },
        {
            title: "Formulario Menor no acompañado",
            image: "https://tamep.reflex.run/fr3.jpeg"
        },
        {
            title: "Formulario Transporte Especial",
            image: "https://tamep.reflex.run/fr1.jpeg"
        }
    ];

    // Función para avanzar a la siguiente diapositiva
    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length); // Incrementa el índice y vuelve al inicio al llegar al final
    };

    // Función para retroceder a la diapositiva anterior
    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length); // Decrementa el índice y vuelve al final si se pasa del inicio
    };

    return (
        // Sección principal con flexbox para alinear los elementos en una columna y fondo de imagen
        <section className="flex flex-col min-h-[85vh] bg-slate-800 text-white bg-cover bg-center"
            // Aplica una imagen de fondo desde una URL externa
            style={{ backgroundImage: "url('https://carlaconwifi.com/wp-content/uploads/2022/07/vuelosbaratos.jpg')" }}>

            {/* Contenedor principal centralizado que agrupa el menú lateral y el contenido */}
            <div className="container-center">

                {/* Menú lateral */}
                <aside className="sidebar-container hidden md:flex">
                    <nav className="sidebar-content">
                        {/* Opción del menú: Equipaje */}
                        <div className="nav-option">
                            <img src={boton1Img} alt="Equipaje" className="icon" />
                            <h3>Equipaje</h3>
                        </div>
                        {/* Opción del menú: Necesidades Especiales */}
                        <div className="nav-option">
                            <img src={boton2Img} alt="Necesidades Especiales" className="icon" />
                            <h3>Necesidades <br /> Especiales</h3>
                        </div>
                        {/* Opción del menú: Formularios */}
                        <div className="nav-option">
                            <img src={boton3Img} alt="Formularios" className="icon" />
                            <h3>Formularios</h3>
                        </div>
                        {/* Opción del menú: Mascotas */}
                        <div className="nav-option">
                            <img src={boton4Img} alt="Mascotas" className="icon" />
                            <h3>Mascotas</h3>
                        </div>
                        {/* Opción del menú: Preguntas Frecuentes */}
                        <div className="nav-option">
                            <img src={boton5Img} alt="Preguntas Frecuentes" className="icon" />
                            <h3>Preguntas <br /> Frecuentes</h3>
                        </div>
                    </nav>
                </aside>

                {/* Contenido principal */}
                <main className="content-section w-full md:w-auto">
                    {/* Contenedor principal para el contenido */}
                    <div className="p-10 bg-white rounded-lg flex flex-col md:flex-row items-center gap-5">
                        <div className="w-full">
                            {/* Título principal del contenido */}
                            <h1 className="text-4xl font-bold text-center text-yellow-400 underline mb-4">
                                {slides[currentSlide].title} {/* Muestra el título de la diapositiva actual */}
                            </h1>

                            {/* Contenedor para la imagen y los botones de navegación */}
                            <div className="flex items-center justify-center w-full mb-[10px]">

                                {/* Botón para ir a la diapositiva anterior */}
                                <button className="max-h-[20vh] mx-2" onClick={prevSlide}>
                                    <img src={flechaIzquierda} alt="Anterior" className="h-10 w-10" />
                                </button>

                                {/* Contenedor para la imagen de la diapositiva actual */}
                                <div className="flex justify-center items-baseline overflow-auto max-h-[80vh] w-[100%] mt-[20px]">
                                    <img
                                        src={slides[currentSlide].image} /* Muestra la imagen de la diapositiva actual */
                                        alt={slides[currentSlide].title} /* Agrega una descripción alternativa para accesibilidad */
                                        className="rounded-lg shadow-md max-w-[500px] max-h-[140vh]"
                                    />
                                </div>

                                {/* Botón para ir a la siguiente diapositiva */}
                                <button className="max-h-[20vh] mx-2" onClick={nextSlide}>
                                    <img src={flechaDerecha} alt="Siguiente" className="h-10 w-10" />
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </section>
    );
};

export default Content13;
